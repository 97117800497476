// -> Import css
import './css/Community.css'

// -> Import Libraries
import { React, useState, useRef, useContext, useEffect } from 'react'
import { ProgressBar } from 'react-rainbow-components';
import FadeIn from 'react-fade-in';
import { AuthContext } from '../services/Auth'
import { Navigate } from 'react-router-dom'
import firebaseConfig from '../services/Auth';
import firebase from 'firebase/compat/app';




// TODO: Add score back to top
export const Community = () => {
    const db = firebaseConfig.firestore();
    const { currentUser } = useContext(AuthContext);
    const database2 = firebase.firestore()
    // const [ranTimes, setRunTimes] = useState(true);
    const [runTimes, setRunTimes] = useState(true);


    const [allUser, setAllUser] = useState([]);
    const [allUser2, setAllUser2] = useState();
    const [allUser3, setAllUser3] = useState('');
    const [allUser4, setAllUser4] = useState();
    const [currentUserProfile, setCurrentUserProfile] = useState(null)
    // const [geneUser, setGeneUser] = useState();

    useEffect(() => {
        setAllUser([])
        database2.collection('users').get().then((users) => {
            users.forEach(doc => {
                console.log(doc.data())
                
                if (runTimes && (allUser.length == 0)) {
                    setAllUser((allUser) => [...allUser, { ...doc.data() }])

                    setRunTimes(false)
                }
            })
        })
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        try {
            // fetch(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`)
            // .then(result => result.json())
            // .then((output) => output['downloadTokens'])
            // .then(token => {
            //     setCurrentUserProfile(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}?alt=media&token=${token}`)
            // })

            // let le = 
            // console.log(allUser)

            while (allUser.length == allUser2.length) {
                setAllUser2([...allUser.slice(0, allUser.length)])
            }

            // if (allUser.length != 0) {
                
            // }
            // console.log(allUser2)
        } catch (error) {

        }
    }, [allUser])





    const Person = ({ name, faculty, hours, budges, instagram = "", profile }) => {
        return (

            <div className="person1">
                <FadeIn className="backgroundFaculty">
                    <img src={require('../image/enkku.jpeg')} alt="EN KKU" />
                </FadeIn>
                <div className="profileImage"  >
                    {/* <img src={profile} alt="Profile" /> */}
                    <div className="bgProfile" style={{backgroundImage: `url(${profile})`}} />
                    {/* <div className="bgProfile"></div> */}
                </div>
                <FadeIn className="name">
                    {name}
                </FadeIn>
                <FadeIn className="faculty">
                    <span>Faculty of {faculty}</span>
                </FadeIn>
                <FadeIn className="badges">
                    <div className="imgIcon"style= {{display: `${(budges[0]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo1.png')} alt="Badges" />
                    </div>
                    <div className="imgIcon"style= {{display: `${(budges[1]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo2.png')} alt="Badges" />
                    </div>
                    <div className="imgIcon" style= {{display: `${(budges[2]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo3.png')} alt="Badges" />
                    </div>
                    <div className="imgIcon"style= {{display: `${(budges[3]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo4.png')} alt="Badges" />
                    </div>
                    <div className="imgIcon"style= {{display: `${(budges[4]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo5.png')} alt="Badges" />
                    </div>
                    <div className="imgIcon"style= {{display: `${(budges[5]) ? 'block' : 'none'}`}}>
                        <img src={require('../logo/badges/badgesLogo6.png')} alt="Badges" />
                    </div>
                </FadeIn>
                <FadeIn className="status">
                    <span className="statusHours">{hours}</span>
                    <span className="statusDes">hours on focus</span>
                </FadeIn>
                <FadeIn className="socialLink">
                    {/* https://www.facebook.com/search/top/?q=chaiwat%20janchalee */}
                    {/* TODO: When hover show link */}
                    {/* <a href="#"><i className="fa-brands fa-facebook socialLinkNotAllowed" style={{fontSize: 20}}/></a> */}
                    <a href={`https://www.instagram.com/${instagram}`}><i className={"fa-brands fa-instagram" +  ((instagram !='') ? "" : "socialLinkNotAllowed")} style={{ fontSize: 20 }} target="_blank"></i></a>
                    {/* <a href="#"><i className="fa-brands fa-twitter socialLinkNotAllowed" style={{fontSize: 20}}/></a> */}
                </FadeIn>
            </div>
        )
    }




    if (currentUser != null) {
        return (
            <>
                <section>
                    <FadeIn className="headerCommunity container">
                        <span>Community 👥</span>
                        <div className="hrHeader"></div>
                    </FadeIn>
                    <FadeIn className="communityMain container" id="mainCom">



                        <Person name="Chaiwat Janchalee" faculty="Engineering" hours="128" budges={[true, true, false, false, false, true]} instagram="oliviarodrigo" profile={require('../profile.jpg')}/>
                        <Person name="Patharanan Papakang" faculty="Engineering" hours="256" budges={[true, true, true, true, false, true]} instagram="oliviarodrigo" profile={require('../care.jpg')}/>
                        <Person name="Phetsiri Kantichot" faculty="Engineering" hours="524" budges={[true, true, true, false, false, true]} instagram="oliviarodrigo" profile={require('../phet.jpg')}/>


                    </FadeIn>
                </section>

            </>
        )
    } else {
        return <Navigate replace to="/signin" />
    }

}

    // const[ student, setStudent ] = useState([]);


    // useEffect(() => {
    //     db.collection('user').get().then((snapshot) => {
    //         snapshot.docs.forEach(doc => {
    //             // console.log(doc.data());
    //             // <Person name={doc.data().name} faculty={doc.data().faculty} hours={doc.data().historyoffocus}/>
    //             setStudent(student => [...student, doc.data()])
    //         })
    //     }).then(()=>{
    //         // console.log(student)
    //     })
    // }, [student])



    // useEffect(() => {
    //     try {
    //         // console.log(allUser2[0].name)

    //         // let st = ``
    //         for (let i = 0; i < allUser2.length; i++) {
    //         //     setAllUser4(<Person name={allUser2[i].name} faculty={allUser2[i].faculty} hours="100" />)
    //         //     setAllUser4((person) => [...setAllUser4, { ...allUser2 }])

    //             // console.log("hi")
    //             // setAllUser4(allUser4.concat(<Person name={allUser2[i].name} faculty={allUser2[i].faculty} hours="100" />))
    //             // console.log(allUser4)

    //         }

    //         // if (allUser3 == '') {
    //         //     setAllUser3(st)

    //         // }
    //         // console.log(allUser2)
    //         // setAllUser4([<Person name={allUser2[0].name} faculty={allUser2[0].faculty} hours="100" />,
    //         //             <Person name={allUser2[1].name} faculty={allUser2[1].faculty} hours="100" />,
    //         //             <Person name={allUser2[2].name} faculty={allUser2[2].faculty} hours="100" />,
    //         //             <Person name={allUser2[3].name} faculty={allUser2[3].faculty} hours="100" />])
    //     } catch (error) {

    //     }
    // }, [allUser2])



        // useEffect(() => {
    //     try {
    //         document.getElementById('mainCom').appendChild(<Person name="Chaiwat Janchalee" faculty="Engineering" hours="100" />)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }, [])


                            {/* {
                            console.log(allUser2)
                        }
                        {
                            allUser2?.map(persons => {
                                // <Person name={person.name} faculty={person.faculty} hours="100" />
                                return <Person name={persons.name} faculty={persons.faculty} hours="100" />
                                // console.log(person.)
                            })
                        }
                        {
                            console.log(allUser2)
                        } */}