// -> Import css
import './css/Home.css'

// -> Import Libraries
import React, { useContext } from 'react'
import FadeIn from 'react-fade-in';
import { AuthContext } from '../services/Auth'
import { Navigate } from 'react-router-dom'


// TODO: Add score back to top

export const Home = () => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser != null) {
        return (
            <>
                <FadeIn className="greetingMain">
                    <div className="container viblyMain">

                        <div className="desMain">
                            <h1 className="viblyHeader">VIBLY</h1>
                            <h2 className="pomo">POMODORO TECHNIQUE</h2>
                            <p className="pomoDes">The Pomodoro Technique is a time management method developed by Francesco Cirillo in the late 1980s. It uses a timer to break work into intervals, traditionally 25 minutes in length, separated by short breaks. Each interval is known as a pomodoro, from the Italian word for tomato, after the tomato-shaped kitchen timer Cirillo used as a university student. <span><a href="https://en.wikipedia.org/wiki/Pomodoro_Technique">(ref:wikipedia)</a></span></p>
                        </div>
                        <div class="img">
                            <img src={require("../images2/viblycontent/clock/clock1.png")} alt="clock1" />
                            <img src={require("../images2/viblycontent/clock/clock2.png")} alt="clock2" />
                            <img src={require("../images2/viblycontent/clock/clock3.png")} alt="clock3" />
                            <img src={require("../images2/viblycontent/clock/base-01.png")} alt="base-01" />
                        </div>

                        
                    </div>


                </FadeIn>
            </>
        )
    } else {
        return <Navigate replace to="/signin" />
    }
}