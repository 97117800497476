// -> Import css
import './css/Navbar.css'

// -> Import Libraries
import { Link, useLocation, Navigate } from 'react-router-dom'
import { React, useState, useEffect, useContext } from 'react'
// import firebaseConfig, { AuthContext } from '../services/Auth'
import firebase from 'firebase/compat/app';
import firebaseConfig, { AuthContext } from '../services/Auth'
import { getStorage, ref, getMetadata } from "firebase/storage";



export const Navbar = () => {
    const { currentUser } = useContext(AuthContext);
    const db = firebaseConfig.firestore();

    const [userName, setUserName] = useState('');
    const [allUser, setAllUser] = useState([])
    const [btnLogout, setBtnLogout] = useState(true);
    const [btnLogout2, setBtnLogout2] = useState(true);
    const [runTimes, setRunTimes] = useState(true);
    const [currentUserProfile, setCurrentUserProfile] = useState(null)
    const [currentUserProfileUrl, setCurrentUserProfileUrl] = useState(null)
    


    useEffect(() => {
        // console.log(currentUser);
        try {
            if (currentUser != null && runTimes) {
                setUserName(currentUser.name.toString())
                // console.log(currentUser.name)
                setRunTimes(false)
            }
        } catch (error) {
            // console.log(error)
        }
    }, [currentUser])


    
    useEffect(() => {
        try {
            fetch(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`)
                .then(result => result.json())
                .then((output) => output['downloadTokens'])
                .then(token => {
                    setCurrentUserProfile(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}?alt=media&token=${token}`)
                }) 
                .catch(err => console.error(err));

        } catch (error) {
            // console.log(error)
        }
    }, [currentUser])





    useEffect(() => {

        setBtnLogout(true)
        setBtnLogout2(true);

    }, [window.location.pathname])





    const logo = require('../logo/ViblyLogo.png')
    const pfIcon = currentUserProfile // -> Import from Avatar

    const [focusNav, setFocusNav] = useState('');
    const [communityNav, setCommunityNav] = useState('');
    const [badgesNav, setBadgesNav] = useState('');

    const logoutBtnNN = () => {
        setBtnLogout2(false);
        setTimeout(() => { firebaseConfig.auth().signOut() }, 1000)
    }



    useEffect(() => {
        switch (window.location.pathname) {
            case '/focus':
                setFocusNav(" isSelected");
                setCommunityNav('');
                setBadgesNav('');
                break;
            case '/community':
                setFocusNav('');
                setCommunityNav(" isSelected");
                setBadgesNav('');
                break;
            case '/badges':
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav(" isSelected");
                break;
            case '/':
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav('');
                break;
            default:
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav('');
                break;
        }
    }, [])

    function checkPathName(pathName) {
        switch (pathName) {
            case '/focus':
                setFocusNav(' isSelected');
                setCommunityNav('');
                setBadgesNav('');
                break;
            case '/community':
                setFocusNav('');
                setCommunityNav(" isSelected");
                setBadgesNav('');
                break;
            case '/badges':
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav(" isSelected");
                break;
            case '/':
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav('');
                break;
            default:
                setFocusNav('');
                setCommunityNav('');
                setBadgesNav('');
                break;
        }
    }



    // console.log(currentUser)
    if (currentUser != null ) {
        return (
            <>
                <nav>
                    <div className="navbar container">

                        {/* Navigation Brand */}
                        <Link className="navBrand" onClick={() => { checkPathName('/') }} to="/">
                            <div className="brandLogo">
                                <img src={logo} alt="logo" />
                            </div>
                            <span className="brandName">VIBLY</span>
                        </Link>


                        {/* Navigation Control */}
                        <div className="navControl">
                            <Link className={"navConIt" + focusNav} onClick={() => { checkPathName('/focus') }} to="/focus" >
                                <i className="fa-solid fa-stopwatch fa-1x" />
                            </Link>
                            <Link className={"navConIt" + communityNav} onClick={() => { checkPathName('/community') }} to="/community">
                                <i className="fa-solid fa-users fa-1x" />
                            </Link>
                            <Link className={"navConIt" + badgesNav} onClick={() => { checkPathName('/badges') }} to="/badges">
                                <i className="fa-solid fa-award fa-1x" />
                            </Link>
                        </div>


                        {/* Navigation Profile */}
                        <div className="logOut">

                            <div id='navLog' className="navLog" onClick={() => { setBtnLogout(!btnLogout) }} >
                                <div className="logCon">
                                    <div className="pfIcon" style={{ backgroundImage: 'url(' + currentUserProfile + ')' }}>
                                        {/* <img src={currentUserProfile} alt="Profile Icon" /> */}
                                    </div>
                                    <span id="name">{userName.split(' ')[0]}</span>
                                    {(!btnLogout) ? <i className="fa-solid fa-angle-up " /> : <i className="fa-solid fa-angle-down " />}

                                </div>
                            </div>

                            <div className="btnLogout" style={{ display: `${(btnLogout) ? 'none' : 'block'}` }}>
                                <div className="pfLogOutMain">
                                    <div className="pfIconLogOut" style={{ backgroundImage: 'url(' + currentUserProfile + ')' }}>
                                        {/* <img src={currentUserProfile} alt="Profile Icon" /> */}
                                    </div>
                                    <div className="pfLogOutName">
                                        <span className="setpfLogOutName">{userName}</span>
                                        <span className="pfLogOutNameDes">See your profile</span>
                                    </div>

                                </div>
                                <div className="pfLogOutMainHr"></div>
                                <div className="btnLogoutLogout" onClick={() => logoutBtnNN()}>
                                    <i className={(btnLogout2) ? "fa-solid fa-right-from-bracket" : "fa fa-circle-o-notch fa-spin"} /><span className="btnLogoutLogoutLogout">Sign out</span>
                                </div>

                            </div>

                        </div>


                    </div>
                </nav>

            </>
        )
    } else {
        return ""
    }
}


    // // Create a reference to the file whose metadata we want to retrieve
    // // https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F92rLutzAvbUwZLrb0oJquxvM8zp2
    // useEffect(() => {
    //     try {
    //         const storage = getStorage();
    //         const forestRef = ref(storage, 'imagesProfile/' + currentUser.uid);
    //         // Get metadata properties
    //         getMetadata(forestRef)
    //             .then((metadata) => {
    //                 // console.log(metadata.downloadTokens);
    //                 setCurrentUserProfile(require(`'${metadata.fullPath}'`))
    //             })
    //             .catch((error) => {
    //                 console.log(error.message);
    //             });

    //         // setCurrentUserProfile(require(vibly2-f4029.appspot.com/imagesProfile/92rLutzAvbUwZLrb0oJquxvM8zp2))
    //         console.log(currentUserProfile)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [])




    // useEffect(() => {
    //     try {
    //         const storage = getStorage();
    //         // const pathReference = ref(storage, 'imagesProfile/' + currentUser.uid);
    //         // console.log(pathReference)
    //         // let phtopath = `https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`
    //         // console.log(phtopath)
    //         fetch(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`)
    //             .then(result => result.json())
    //             .then((output) => output['downloadTokens'])
    //             .then(token => {
    //                 setCurrentUserProfile(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}?alt=media&token=${token}`)
    //             })
    //             .then(() => {
    //                 console.log("Load finished")
    //             })
                
    //             .catch(err => console.error(err));

            
    //         // console.log(currentUserProfile)
    //     } catch (error) {
    //         // console.log(error)
    //     }
    // }, [currentUser])


        // https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2FKiAuPjQOjkYwOZS8vmAuYazRNUS2?alt=media&token=a87604f3-28db-414a-ab32-3b3e03910f5f
    // useEffect(() => {
    //     try {
    //         const storage = getStorage();
    //         // const pathReference = ref(storage, 'imagesProfile/' + currentUser.uid);
    //         // console.log(pathReference)
    //         // let phtopath = `https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`
    //         // console.log(phtopath)
    //         async function fetchData() {
    //             await fetch(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`)
    //                 .then(result => result.json())
    //                 .then((output) => output['downloadTokens'])
    //                 .then(token => {
    //                     setCurrentUserProfile(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}?alt=media&token=${token}`)
    //                 })
    //                 .then(() => {
    //                     console.log("Load finished")
    //                 })
                    
    //                 .catch(err => console.error(err));
    //        }
    //         fetchData();
            
    //     } catch (error) {
    //         // console.log(error)
    //     }
    // }, [currentUser])




    // useEffect(() => {
    //     db.collection('user').get().then((snapshot) => {
    //         snapshot.docs.forEach(doc => {
    //             try {
    //                 setAllUser(allUser => [...allUser, doc.data()])
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //         })
    //     }).then(() => {
    //         // console.log(allUser)
    //     })

    // }, [])



    // useEffect(() => {
    //     // console.log(allUser)
    // }, [allUser])



        // document.addEventListener('click', () => {
    //     if (wind)

    // })

    // if (document.getElementById('navLog').addEventListener()) {
    //     setBtnLogout(true)
    //     setBtnLogout2(true);
    // }


        
    // useEffect(() => {
    //     try {
    //         const storage = getStorage();
    //         // const pathReference = ref(storage, 'imagesProfile/' + currentUser.uid);
    //         // console.log(pathReference)
    //         // let phtopath = `https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`
    //         // console.log(phtopath)
    //         fetch(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}`)
    //             .then(result => result.json())
    //             .then((output) => output['downloadTokens'])
    //             .then(token => {
    //                 setCurrentUserProfile(`https://firebasestorage.googleapis.com/v0/b/vibly2-f4029.appspot.com/o/imagesProfile%2F${currentUser.uid}?alt=media&token=${token}`)
    //             })
    //             // .then(() => {
    //             //     console.log("Load finished")
    //             //     setLoading(true)
    //             // })
                
    //             .catch(err => console.error(err));

            
    //         // console.log(currentUserProfile)
    //     } catch (error) {
    //         // console.log(error)
    //     }
    // }, [currentUser])