import React, { useState, useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import FadeIn from 'react-fade-in';
import firebaseConfig, { AuthContext, storageRef } from '../services/Auth'
import firebase from 'firebase/compat/app';
import { getDatabase, set } from 'firebase/database';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import 'firebase/storage';
import { initializeApp } from "firebase/app";
import { getApp } from "firebase/app";



import './css/SignUp.css'


export const SignUp = () => {
    // const { currentUser, setCurrentUser } = useContext(AuthContext);
    // setCurrentUser(null)

    // Initialize Firebase
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    } else {
        firebase.app();
    }


    const database2 = firebase.firestore()
    const auth = firebase.auth()
    const storage = getStorage()
    // console.log(storage)



    const [currentUser, setCurrentUser] = useState(null);
    const [facultyInput, setFacultyInput] = useState('Engineering')
    const [passwordShown, setPasswordShown] = useState(false);
    const [createAccountText, setCreateAccountText] = useState(false);





    const [imageProfile, setImageProfile] = useState(null)
    // const [uploadTask, setUploadTask] = useState(null)


    const onFileChange = (e) => {
        if (e.target.files[0]) {
            setImageProfile(e.target.files[0])
        }

    }


    const handleSubmit2 = (e) => {
        e.preventDefault();
        setCreateAccountText(true);

        const { email, password, name, lastName, instagram, faculty } = e.target.elements;
        try {
            // console.log(createUser)
            setTimeout(() => {

                auth.createUserWithEmailAndPassword(email.value, password.value)
                    .then(() => {
                        setCurrentUser(true);
                        database2.collection('users').add({
                            email: email.value,
                            name: name.value + " " + lastName.value,
                            faculty: facultyInput,
                            instagram: instagram.value,
                            badges: {
                                level1: false,
                                level2: false,
                                level3: false,
                                level4: false,
                                level5: false,
                                level6: false
                            },
                            historyOfFucus: [0],
                            uid: auth.currentUser.uid
                        })
                        const storageRef = ref(storage, 'imagesProfile/' + auth.currentUser.uid)
                        const metadata = {
                            contentType: "image/jpeg",
                        }
                        const uploadTask = uploadBytes(storageRef, imageProfile, metadata)

                    })
                    .then(() => {
                        // alert('Data stored successfully')
                    })
                    .catch((error) => {
                        alert("unsunccessful, erorr: " + error)
                    })


            }, 1050)

        } catch (error) {
            alert(error);
        }
    }










    if (currentUser != null) {
        return <Navigate replace to="/" />
    }

    // if (currentUser != null) {
    //     firebaseConfig.auth().signOut().then(() => {
    //         // return <a href="/signin" />
    //     })

    // }

    console.log(currentUser)


    return (
        <>
            <div className="filterSignUp"></div>
            <div className="backgroundSignUp" style={{ backgroundImage: `url(${require("../image/Background/index.webp")})` }}></div>
            {/* <div class="wrapper"></div> */}
            <div className="container containerSignUp">
                <FadeIn className="brandSignUp">
                    <span className="brandNamesignUp textRainbow">VIBLY</span>
                    <span className="brandDesSignUp">Sign in or create an account</span>
                </FadeIn>
                <FadeIn className="formMain formMainSignUp">

                    <h1 className="titleSignUp">Sign Up</h1>
                    <span>Already have an account?&nbsp;<a className="linkSignUp" href="/signin">Sign in here</a></span>
                    <form onSubmit={handleSubmit2} className="formContainerSignUp">
                        <div className="txtFieldSignUp emailColorSignUp">
                            <input type="email" name="email" className="formControlSignUP emailColorSignUp" aria-describedby="emailHelp" autoComplete="off" required />
                            <span className="emailColorSignUp"></span>
                            <label className="form-label icon">Email address</label>
                        </div>
                        <div className="nameAndLastName">
                            <div className="txtFieldSignUp nameInput nameRainbow nameColorSignUp">
                                <input type="name" name="name" className="formControlSignUP nameColorSignUp" autoComplete='off' required />
                                <span className="nameColorSignUp"></span>
                                <label className="form-label">Name</label>
                            </div>
                            <div className="txtFieldSignUp nameInput lastNameColorSignUp">
                                <input type="name" name="lastName" className="formControlSignUP lastNameColorSignUp" autoComplete='off' required />
                                <span className="lastNameColorSignUp"></span>
                                <label className="form-label">Last name</label>
                            </div>

                        </div>
                        <span className="noticeAboutName">Make sure you fill out all of them correctly because we will use it to create a certificate for you</span>
                        <div className="txtFieldSignUp passwordColorSignUp">
                            <input type={!passwordShown ? "password" : "text"} name="password" className="formControlSignUP passwordColorSignUp" autoComplete='off' required />
                            <span className="passwordColorSignUp"></span>
                            <label className="form-label">Password</label>
                            <div className="showPassword" onClick={() => { setPasswordShown(!passwordShown) }}>
                                {!passwordShown ? <i className="fa-solid fa-eye-slash" /> : <i class="fa-solid fa-eye" />}
                            </div>
                        </div>
                        <div className="dropdownFieldSignUp">
                            <label className="">Faculty</label>
                            <select className="dropdownSelectSignUp" value={facultyInput} onChange={(e) => { setFacultyInput(e.target.value) }}>
                                {/* <option value=" ">---</option> */}
                                <option value="Agriculture">Agriculture</option>
                                <option value="Applied Science and Engineering">Applied Science and Engineering</option>
                                <option value="Architecture">Architecture</option>
                                <option value="Associated Medical Sciences">Associated Medical Sciences</option>
                                <option value="Business Administration">Business Administration</option>
                                <option value="Business Administration and Accountancy">Business Administration and Accountancy</option>
                                <option value="College of Computing">College of Computing</option>
                                <option value="College of Graduate Study in Management">College of Graduate Study in Management</option>
                                <option value="College of Local Administration">College of Local Administration</option>
                                <option value="Dentistry">Dentistry</option>
                                <option value="Economics">Economics</option>
                                <option value="Education">Education</option>
                                <option value="Engineering">Engineering</option>
                                <option value="Fine and Applied Arts">Fine and Applied Arts</option>
                                <option value="General Education">General Education</option>
                                <option value="Graduate School">Graduate School</option>
                                <option value="Humanities and Social Sciences">Humanities and Social Sciences</option>
                                <option value="Integrated Social Sciences">Integrated Social Sciences</option>
                                <option value="Interdisciplinary Studies">Interdisciplinary Studies</option>
                                <option value="International College">International College</option>
                                <option value="Law">Law</option>
                                <option value="Liberal Arts">Liberal Arts</option>
                                <option value="Management Sciences">Management Sciences</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Nong Khai Campus">Nong Khai Campus</option>
                                <option value="Nursing">Nursing</option>
                                <option value="Pharmaceutical Sciences">Pharmaceutical Sciences</option>
                                <option value="Public Health">Public Health</option>
                                <option value="Science">Science</option>
                                <option value="Technology">Technology</option>
                                <option value="Veterinary Medicine">Veterinary Medicine</option>
                            </select>
                        </div>
                        <div className="txtFieldSignUp instagramColorSignUp">
                            <input type="text" name="instagram" className="formControlSignUP instagramColorSignUp" autoComplete='off' required />
                            <span className="instagramColorSignUp"></span>
                            <label className="form-label instagramIconSignUp"><i className="fa-brands fa-instagram"></i>Instagram</label>
                        </div>
                        <div className="fileFieldSignUp">
                            <label className="form-label">Upload your profile picture</label>
                            {/* <input type="file" name="useProfile" id="useProfile" onChange={onFileChange} /> */}
                            <div className="wrapper">
                                <div className={"file-upload " + ((imageProfile != null) ? "fileSelected" : "")}>
                                    <input type="file" name="userProfile" id="userProfile" onChange={onFileChange} required/>
                                    <i className={((imageProfile != null) ? "fa fa-image" : "fa-solid fa-arrow-up")} />
                                </div>
                            </div>

                        </div>
                        <div className="submitBtn">
                            <button type="submit" className="btnSubmitSignUp" ><i className={(createAccountText) ? "fa fa-circle-o-notch fa-spin" : ""} /> Create account</button>
                        </div>
                    </form>

                </FadeIn>
                <div className="developBy">
                    <span><i className="fa-solid fa-code"></i>&nbsp;Develop by <b><i className="developByAnimateCharcter">MyKiWx x DubuShii x CarePhat</i></b></span>
                </div>

            </div>

        </>
    )
}


    // useEffect(() => {
    //     try {
    //         // console.log(imageProfile)
    //         // console.log(imageProfile.name)
    //         // console.log(imageProfile.lastModified)
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }, [imageProfile])

    // useEffect(() => {
    //     try {
    //         // setUploadTask(storage(`images/${imageProfile.name}`).put(imageProfile))
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [imageProfile])


// export default SignUp;


// database.collection('users').add(createUser)
// var user = auth.currentUser;
// var database_ref = database.ref()

// setTimeout(() => {database.collection('users').add(createUser)}, 500)
// database.ref().child('user/' + auth.currentUser.user.uid).set(createUser)

// set(ref(database, 'users/' + auth.currentUser.uid), {
//     email: email.value,
//     name: name.value + " " + lastName.value,
//     faculty: facultyInput,
//     instagram: instagram.value,
//     badges: {
//         level1: false,
//         level2: false,
//         level3: false,
//         level4: false,
//         level5: false,
//         level6: false
//     },
//     historyOfFucus: [0],
//     uid: auth.currentUser.uid
// })


    // const [createUser, setCreateUser] = useState({
    //     email: '',
    //     name: '',
    //     lastName: '',
    //     faculty: facultyInput,
    //     instagram: '',
    //     badges: {
    //         level1: false,
    //         level2: false,
    //         level3: false,
    //         level4: false,
    //         level5: false,
    //         level6: false
    //     },
    //     historyOfFucus: [0],
    // });
    // const storage = getStorage(firebaseConfig)



    // const onFileChange = (e) => {
    //     // console.log(storage)

    //     if (e.target.files[0]) {
    //         // const uploadTask  = storage.child("userProfile/"+ e.target.files[0].name);

    //         // const storageRef = ref(storage, 'images/mountains.jpg')
    //         // const metadata = {
    //         //     contentType: "image/jpeg",
    //         // }
    //         // const uploadTask = uploadBytes(storageRef, e.target.files[0], metadata)

    //         const storageRef = ref(storage, 'images/' + e.target.files[0].name)
    //         const metadata = {
    //             contentType: "image/jpeg",
    //         }
    //         const uploadTask = uploadBytes(storageRef, e.target.files[0], metadata)


    //         // uploadTask
    //         //     .put(e.target.files[0].image)
    //         //     .then(() => {
    //         //         alert("Successfully uploaded")
    //         //     })
    //         //     .catch((e) => {
    //         //         alert("Error uploading " + e)
    //         //     })
    //         // console.log(e.target.files[0])
    //         // console.log(e.target.files[0].type)
    //         // console.log(e.target.files[0].name)
    //         // console.log(e.target.files[0].image)
    //     }
    // }