// -> Import css
import './css/Badges.css'

// -> Import Libraries
import React, { useState, useEffect, useRef, useContext } from 'react'
import FadeIn from 'react-fade-in';
import { ProgressBar } from 'react-rainbow-components';
import { Navigate } from 'react-router-dom'
import firebaseConfig, { AuthContext } from '../services/Auth'
import firebase from 'firebase/compat/app';


// TODO: Add score back to top

export const Badges = () => {
    const { currentUser } = useContext(AuthContext);
    const [userName, setUserName] = useState('');
    const [totalHoursFocus, setTotalHoursFocus] = useState(0)
    const [runTimes, setRunTimes] = useState(true);
    
    const hoursToUnlock = [29, 73, 181, 367, 619, 977]



    useEffect(() => {
        // console.log(currentUser);
        try {
            if(currentUser != null && runTimes){
                setUserName(currentUser.name.toString())
                let total = 0
                for (let index = 0; index < currentUser.historyOfFucus.length; index++) {
                    total += currentUser.historyOfFucus[index];
                }
                setTotalHoursFocus(Math.round((total*100)/60)/100);

                setRunTimes(false)
            }
        } catch (error) {
            console.log(error)
        }
    }, [currentUser])

    
    

    useEffect(() => {
        const ctx1 = document.getElementById('canvas1').getContext('2d')
        const ctx2 = document.getElementById('canvas2').getContext('2d')
        const ctx3 = document.getElementById('canvas3').getContext('2d')
        const ctx4 = document.getElementById('canvas4').getContext('2d')
        const ctx5 = document.getElementById('canvas5').getContext('2d')
        const ctx6 = document.getElementById('canvas6').getContext('2d')

        const image1 = new Image()
        const image2 = new Image()
        const image3 = new Image()
        const image4 = new Image()
        const image5 = new Image()
        const image6 = new Image()

        image1.src = require('../image/certificates/1.png')
        image2.src = require('../image/certificates/2.png')
        image3.src = require('../image/certificates/3.png')
        image4.src = require('../image/certificates/4.png')
        image5.src = require('../image/certificates/5.png')
        image6.src = require('../image/certificates/6.png')

        image1.onload = function () {
            ctx1.drawImage(image1, 0, 0, document.getElementById('canvas1').width, document.getElementById('canvas1').height)
            ctx1.font = '600 italic 100px Poppins'
            ctx1.fillStyle = '#14142f'
            ctx1.textAlign = "center";
            ctx1.fillText(userName, 1000, 700)
        }
        image2.onload = function () {
            ctx2.drawImage(image2, 0, 0, document.getElementById('canvas2').width, document.getElementById('canvas2').height)
            ctx2.font = '600 italic 100px Poppins'
            ctx2.fillStyle = '#14142f'
            ctx2.textAlign = "center";
            ctx2.fillText(userName, 1000, 700)
        }
        image3.onload = function () {
            ctx3.drawImage(image3, 0, 0, document.getElementById('canvas3').width, document.getElementById('canvas3').height)
            ctx3.font = '600 italic 100px Poppins'
            ctx3.fillStyle = '#14142f'
            ctx3.textAlign = "center";
            ctx3.fillText(userName, 1000, 700)
        }
        image4.onload = function () {
            ctx4.drawImage(image4, 0, 0, document.getElementById('canvas4').width, document.getElementById('canvas4').height)
            ctx4.font = '600 italic 100px Poppins'
            ctx4.fillStyle = '#14142f'
            ctx4.textAlign = "center";
            ctx4.fillText(userName, 1000, 700)
        }
        image5.onload = function () {
            ctx5.drawImage(image5, 0, 0, document.getElementById('canvas5').width, document.getElementById('canvas5').height)
            ctx5.font = '600 italic 100px Poppins'
            ctx5.fillStyle = '#14142f'
            ctx5.textAlign = "center";
            ctx5.fillText(userName, 1000, 700)
        }
        image6.onload = function () {ctx6.drawImage(image6, 0, 0, document.getElementById('canvas6').width, document.getElementById('canvas6').height)
        ctx6.font = '600 italic 100px Poppins'
        ctx6.fillStyle = '#14142f'
        ctx6.textAlign = "center";
        ctx6.fillText(userName, 1000, 700)}

    }, [userName])

    const createCertificate = (idCerti) => {
        if (idCerti == 1) {
            document.getElementById('download-btn1').href = document.getElementById('canvas1').toDataURL('image/jpg')
            document.getElementById('download-btn1').download = 'Certificate - ' + userName
        } else if (idCerti == 2) {
            document.getElementById('download-btn2').href = document.getElementById('canvas2').toDataURL('image/jpg')
            document.getElementById('download-btn2').download = 'Certificate - ' + userName
        } else if (idCerti == 3) {
            document.getElementById('download-btn3').href = document.getElementById('canvas3').toDataURL('image/jpg')
            document.getElementById('download-btn3').download = 'Certificate - ' + userName
        } else if (idCerti == 4) {
            document.getElementById('download-btn4').href = document.getElementById('canvas4').toDataURL('image/jpg')
            document.getElementById('download-btn4').download = 'Certificate - ' + userName
        } else if (idCerti == 5) {
            document.getElementById('download-btn5').href = document.getElementById('canvas5').toDataURL('image/jpg')
            document.getElementById('download-btn5').download = 'Certificate - ' + userName
        } else if (idCerti == 6) {
            document.getElementById('download-btn6').href = document.getElementById('canvas6').toDataURL('image/jpg')
            document.getElementById('download-btn6').download = 'Certificate - ' + userName
        }
    }

    const Certi = ({ certiName, certiDesHours, percent, certificateURLPR, logoURLPR, idCerti }) => {
        return (
            <>
                <div className="certiMain">
                    <img className="certi" src={certificateURLPR} alt="Certificate" />
                    <FadeIn className="certiSub">
                        <div className="certiLogo">
                            <img src={logoURLPR} alt="LogoCertificate" />
                        </div>

                        <span className="certiName">{certiName}</span>
                        <span className="certiDes">You need to focus for <b>{certiDesHours}</b>  hours for get this certificate</span>
                        <div className="progressCertiContainer">
                            {(percent != 100) ? <ProgressBar className="progressCerti" value={percent} size="medium" variant="success" /> : <span className="doneMs" >Done 🎉</span>}
                            {(percent != 100) ? <span className="doneMs2" >{Math.floor(percent*100)/100} %</span> : ""}
                        </div>
                        {(percent == 100) ?
                            <div className="iconDownloadAllowed">
                                <a id={"download-btn" + idCerti} onClick={()=>{createCertificate(idCerti)}}><i className="fa-solid fa-file-arrow-down"></i></a>
                            </div>
                            : ""}
                    </FadeIn>

                </div>
            </>
        )
    }
    const Certi2 = () => {
        return (
            <>
                <div className="certiMain filterGreyMain">
                    <div className="filterGrey"></div>
                    <img className="certi" src={require('../image/certificatesResize/1.jpeg')} alt="Certificate" />
                    <FadeIn className="certiSub">
                        <div className="certiLogo">
                            <img src={require('../logo/badges/badgesLogo1.png')} alt="LogoCertificate" />
                        </div>

                        <span className="certiName">Coming soon</span>
                        <span className="certiDes"> </span>
                        <div className="progressCertiContainer">
                            {/* {(percent != 100) ? <ProgressBar className="progressCerti" value={percent} size="medium" variant="success" /> : <span className="doneMs" >Done 🎉</span>} */}
                        </div>

                    </FadeIn>

                </div>
            </>
        )
    }

    
    if (currentUser != null) {
        return (
            <>
                <section className="badgesSec">
                <FadeIn className="headerBadges container">
                    <span > Certificates 📜 </span>
                    <div className="hrHeader"></div>
                </FadeIn>
                <span id="showTotal"><b>Total</b> : {totalHoursFocus} hours</span>
                <FadeIn className="badgesMain container">

                    <Certi certiName={"Newbie"} certiDesHours={29} percent={(((totalHoursFocus / hoursToUnlock[0]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[0]) * 100)} certificateURLPR={require('../image/certificatesResize/1.jpeg')} logoURLPR={require('../logo/badges/badgesLogo1.png')} idCerti="1" />
                    <Certi certiName={"Rookie"} certiDesHours={73} percent={(((totalHoursFocus / hoursToUnlock[1]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[1]) * 100)} certificateURLPR={require('../image/certificatesResize/2.jpeg')} logoURLPR={require('../logo/badges/badgesLogo2.png')} idCerti="2" />
                    <Certi certiName={"Talented"} certiDesHours={181} percent={(((totalHoursFocus / hoursToUnlock[2]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[2]) * 100)} certificateURLPR={require('../image/certificatesResize/3.jpeg')} logoURLPR={require('../logo/badges/badgesLogo3.png')}  idCerti="3" />
                    <Certi certiName={"Intermediate"} certiDesHours={367} percent={(((totalHoursFocus / hoursToUnlock[3]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[3]) * 100)} certificateURLPR={require('../image/certificatesResize/4.jpeg')} logoURLPR={require('../logo/badges/badgesLogo4.png')} idCerti="4" />
                    <Certi certiName={"Proficient"} certiDesHours={619} percent={(((totalHoursFocus / hoursToUnlock[4]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[4]) * 100)} certificateURLPR={require('../image/certificatesResize/5.jpeg')} logoURLPR={require('../logo/badges/badgesLogo5.png')} idCerti="5" />
                    <Certi certiName={"Expert"} certiDesHours={977} percent={(((totalHoursFocus / hoursToUnlock[5]) * 100) > 100 ? 100 : (totalHoursFocus / hoursToUnlock[5]) * 100)} certificateURLPR={require('../image/certificatesResize/6.jpeg')} logoURLPR={require('../logo/badges/badgesLogo6.png')} idCerti="6" />
                    <Certi2 />
                    <Certi2 />
                    

                </FadeIn>
                <section style={{ width: '100%', height: '2000px', padding: 100, display: 'none' }}>

                    <div className="certificate-main">
                        <div className="header-text">
                            <span className="">Generate certificate</span>
                        </div>
                        <div className="canvas-gene-img">
                            <canvas id="canvas1" height="1414px" width="2000px"></canvas>
                            <canvas id="canvas2" height="1414px" width="2000px"></canvas>
                            <canvas id="canvas3" height="1414px" width="2000px"></canvas>
                            <canvas id="canvas4" height="1414px" width="2000px"></canvas>
                            <canvas id="canvas5" height="1414px" width="2000px"></canvas>
                            <canvas id="canvas6" height="1414px" width="2000px"></canvas>
                        </div>


                    </div>
                </section>
                </section>
            </>
        )
    } else {
        return <Navigate replace to="/signin" />
    }
}



// const [image1, setImage1] = useState(new Image());
// const [image2, setImage2] = useState(new Image());
// const [image3, setImage3] = useState(new Image());
// const [image4, setImage4] = useState(new Image());
// const [image5, setImage5] = useState(new Image());
// const [image6, setImage6] = useState(new Image());

// const [canvas1, setCanvas1] = useState();
// const [canvas2, setCanvas2] = useState();
// const [canvas3, setCanvas3] = useState();
// const [canvas4, setCanvas4] = useState();
// const [canvas5, setCanvas5] = useState();
// const [canvas6, setCanvas6] = useState();

// const [ctx1, setCtx1] = useState();
// const [ctx2, setCtx2] = useState();
// const [ctx3, setCtx3] = useState();
// const [ctx4, setCtx4] = useState();
// const [ctx5, setCtx5] = useState();
// const [ctx6, setCtx6] = useState();

// const [downloadBtn1, steDownloadBtn1] = useState();
// const [downloadBtn2, steDownloadBtn2] = useState();
// const [downloadBtn3, steDownloadBtn3] = useState();
// const [downloadBtn4, steDownloadBtn4] = useState();
// const [downloadBtn5, steDownloadBtn5] = useState();
// const [downloadBtn6, steDownloadBtn6] = useState();

// useEffect(() => {
//     image1.src = require('../image/certificates/1.png')
//     image2.src = require('../image/certificates/2.png')
//     image3.src = require('../image/certificates/3.png')
//     image4.src = require('../image/certificates/4.png')
//     image5.src = require('../image/certificates/5.png')
//     image6.src = require('../image/certificates/6.png')
    
//     image1.onload = function () {drawImage1()}
//     image2.onload = function () {drawImage2()}
//     image3.onload = function () {drawImage3()}
//     image4.onload = function () {drawImage4()}
//     image5.onload = function () {drawImage5()}
//     image6.onload = function () {drawImage6()}

//     setCanvas1(document.getElementById('canvas1'));
//     setCanvas2(document.getElementById('canvas2'));
//     setCanvas3(document.getElementById('canvas3'));
//     setCanvas4(document.getElementById('canvas4'));
//     setCanvas5(document.getElementById('canvas5'));
//     setCanvas6(document.getElementById('canvas6'));

//     setCtx1(canvas1.getContext('2d'));
//     setCtx2(canvas2.getContext('2d'));
//     setCtx3(canvas3.getContext('2d'));
//     setCtx4(canvas4.getContext('2d'));
//     setCtx5(canvas5.getContext('2d'));
//     setCtx6(canvas6.getContext('2d'));

//     steDownloadBtn1(document.getElementById('download-btn1'))
//     steDownloadBtn2(document.getElementById('download-btn2'))
//     steDownloadBtn3(document.getElementById('download-btn3'))
//     steDownloadBtn4(document.getElementById('download-btn4'))
//     steDownloadBtn5(document.getElementById('download-btn5'))
//     steDownloadBtn6(document.getElementById('download-btn6'))



//     function drawImage1() {
//         ctx1.drawImage(image1, 0, 0, canvas1.width, canvas1.height)
//         ctx1.font = '600 italic 100px Poppins'
//         ctx1.fillStyle = '#14142f'
//         ctx1.textAlign = "center";
//         ctx1.fillText(userName, 1000, 700)
//     }  
//     function drawImage2() {
//         ctx2.drawImage(image2, 0, 0, canvas2.width, canvas2.height)
//         ctx2.font = '600 italic 100px Poppins'
//         ctx2.fillStyle = '#14142f'
//         ctx2.textAlign = "center";
//         ctx2.fillText(userName, 1000, 700)
//     }
//     function drawImage3() {
//         ctx3.drawImage(image3, 0, 0, canvas3.width, canvas3.height)
//         ctx3.font = '600 italic 100px Poppins'
//         ctx3.fillStyle = '#14142f'
//         ctx3.textAlign = "center";
//         ctx3.fillText(userName, 1000, 700)
//     }
//     function drawImage4() {
//         ctx4.drawImage(image4, 0, 0, canvas4.width, canvas4.height)
//         ctx4.font = '600 italic 100px Poppins'
//         ctx4.fillStyle = '#14142f'
//         ctx4.textAlign = "center";
//         ctx4.fillText(userName, 1000, 700)
//     }
//     function drawImage5() {
//         ctx5.drawImage(image5, 0, 0, canvas5.width, canvas5.height)
//         ctx5.font = '600 italic 100px Poppins'
//         ctx5.fillStyle = '#14142f'
//         ctx5.textAlign = "center";
//         ctx5.fillText(userName, 1000, 700)
//     }
//     function drawImage6() {
//         ctx6.drawImage(image6, 0, 0, canvas6.width, canvas6.height)
//         ctx6.font = '600 italic 100px Poppins'
//         ctx6.fillStyle = '#14142f'
//         ctx6.textAlign = "center";
//         ctx6.fillText(userName, 1000, 700)
//     }


// }, [])

// const createCertificate = (idCerti) => {
//     if (idCerti == 1) {
//         downloadBtn1.href = canvas1.toDataURL('image/jpg')
//         downloadBtn1.download = 'Certificate - ' + userName
//     } else if (idCerti == 2) {
//         downloadBtn2.href = canvas2.toDataURL('image/jpg')
//         downloadBtn2.download = 'Certificate - ' + userName
//     } else if (idCerti == 3) {
//         downloadBtn3.href = canvas3.toDataURL('image/jpg')
//         downloadBtn3.download = 'Certificate - ' + userName
//     } else if (idCerti == 4) {
//         downloadBtn4.href = canvas4.toDataURL('image/jpg')
//         downloadBtn4.download = 'Certificate - ' + userName
//     } else if (idCerti == 5) {
//         downloadBtn5.href = canvas5.toDataURL('image/jpg')
//         downloadBtn5.download = 'Certificate - ' + userName
//     } else if (idCerti == 6) {
//         downloadBtn6.href = canvas6.toDataURL('image/jpg')
//         downloadBtn6.download = 'Certificate - ' + userName
//     }

// }





    // useEffect(() => {
    //     const canvas = document.getElementById('canvas')
    //     const ctx = canvas.getContext('2d')
    //     const nameInput = document.getElementById('name')
    //     const downloadBtn = document.getElementById('download-btn')
    //     const image = new Image()
    //     image.src = require('../image/certificates/1.png')
    //     image.onload = function () {
    //         drawImage()
    //     }
    //     nameInput.addEventListener('input', function () {
    //         drawImage()
    //     })
    //     downloadBtn.addEventListener('click', function () {
    //         downloadBtn.href = canvas.toDataURL('image/jpg')
    //         downloadBtn.download = 'Certificate - ' + name
    //     })
    //     function drawImage() {
    //         // ctx.clearRect(0, 0, canvas.width, canvas.height)
    //         ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    //         ctx.font = '600 italic 100px Poppins'
    //         ctx.fillStyle = '#14142f'
    //         ctx.textAlign = "center";
    //         ctx.fillText(name, 1000, 700)
    //     }
    // }, [])

// useEffect(() => {
//     const canvas = document.getElementById('canvas')
//     const ctx = canvas.current.getContext("2d")
//     const nameInput = document.getElementById('name')
//     const downloadBtn = document.getElementById('downloadCert')
//     const image = new Image()
//     image.src = require('../image/certificates/1.png')
//     image.onload = function () {
//         ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
//         ctx.font = '30px Poppins'
//         ctx.fillStyle = '#0e2252'
//         ctx.textAlign = "center";
//         ctx.fillText(nameInput.value, 250, 185)
//     }
// }, [])




// const downloadCerti = () => {
//     const link = document.getElementById('downloadCert');
//     // link.href = url;
//     link.setAttribute(
//         'download',
//         `FileName.pdf`,
//     );
//     document.body.appendChild(link);
//     link.click();
//     link.parentNode.removeChild(link);

// }












//  {/* , display: 'none'  */}
//  <section style={{ width: '100%', height: '2000px', padding: 100 }}>
//  {/* <div >
//      <h1>Cat Meme!</h1>

//      <div>
//          <canvas
//              ref={canvas}
//              width={1000}
//              height={707}
//          />
//      </div>
//  </div> */}

//  <div class="certificate-main">
//      <div class="header-text">
//          <span class="">Generate certificate</span>
//      </div>

//      <div class="canvas-gene-img">
//          <canvas id="canvas" height="350px" width="500px"></canvas>
//      </div>

//      <div class="menu-bar">
//          <label>Name:</label>
//          <input id="name" type='text' />
//          <a href="#" id="download-btn"><span class="material-icons">file_download</span></a>
//      </div>


//  </div>
// </section>

// const [image, setImage] = useState(null)
    // // const canvas = useRef(null)
    // const [topText, setTopText] = useState('Chaiwat Janchalee')
    // const [bottomText, setBottomText] = useState('')
    // // const catImage = new Image();

    // // useEffect(() => {
    // //     const catImage = new Image();
    // //     catImage.src = require('../image/certificates/1.png')
    // //     catImage.onload = () => setImage(catImage)

    // // }, [])
    // useEffect(() => {
    //     if (image && canvas) {
    //         const ctx = canvas.current.getContext("2d")
    //         ctx.fillStyle = "black"
    //         ctx.fillRect(0, 0, 1000, 707)
    //         ctx.drawImage(image, 0, 0, 1000, 707)

    //         ctx.font = "40px Poppins Sans MS"
    //         ctx.fillStyle = "black"
    //         ctx.textAlign = "center"
    //         // ctx.fillText("textAlign=center",150, 120);
    //         ctx.textAlign = "center";
    //         ctx.fillText(topText, 505, 350)
    //         // ctx.fillText(bottomText, (400 / 2), 256 + 40 + 25)

    //     }
    // }, [image, canvas, topText, bottomText])

    // // const downloadBtn = (e) => {
    // //     const catImage = new Image();
    // //     catImage.src = require('../image/certificates/1.png')
    // //     catImage.onload = () => setImage(catImage)
    // //     // e.preventDefault();

    // //     // console.log(e)

    // //     if (image && canvas) {
    // //         const ctx = canvas.current.getContext("2d")
    // //         ctx.fillStyle = "black"
    // //         ctx.fillRect(0, 0, 1000, 707)
    // //         ctx.drawImage(image, 0, 0, 1000, 707)

    // //         ctx.font = "40px Poppins Sans MS"
    // //         ctx.fillStyle = "black"
    // //         ctx.textAlign = "center"
    // //         ctx.fillText(topText, 505, 350)

    // //     }
    // //     // canvas.toDataURL('image/jpg')
    // //     // var dataImg = me.refs.canvas.toDataURL('image/jpg')
    // // }