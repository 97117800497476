// -> Import css
import './css/Focus.css'
import './css/Sound.css'

// -> Import Libraries
import { React, useState, useRef, useContext, useEffect } from 'react'
import { ProgressBar } from 'react-rainbow-components';
import FadeIn from 'react-fade-in';
import { AuthContext } from '../services/Auth'
import { Navigate } from 'react-router-dom'
import { Sound } from './Sound'
import firebase from 'firebase/compat/app';


export const Focus = () => {
    

    // console.log(currentUser.uid);

    const [pomo, setPomo] = useState(' modeIsActive');
    const [cust, setCust] = useState(' custrd-8');
    const [currentMode, setCurrentMode] = useState('');

    function setActiveMode(mode) {
        console.log(pomo)
        switch (mode) {
            case 'pomoMode':
                setPomo(' modeIsActive')
                setCust(' custrd-8')
                setCurrentMode('Pomodoro')
                break;
            case 'custMode':
                setPomo(' pomord-8')
                setCust(' modeIsActive')
                setCurrentMode('Custom')
                break;
        }
    }

    // -> Round of focus
    const [roundFoc, setRoundFoc] = useState(0);
    const [timerForFocus, setTimerForFocus] = useState()


    // -> Use state for counter time
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    const [timerProgress, setTimerProgress] = useState(0);
    const [progressVariant, setProgressVariant] = useState("ontask2");
    const [timeOnTask, setTimeOnTask] = useState(25);
    const [timeRest, setTimeRest] = useState(5);

    // -> Use state for change Progress mode
    const [onTaskMode, setOnTaskMode] = useState(' progActive');
    const [restMode, setRestMode] = useState('');
    const [doneMode, setDoneMode] = useState('');
    const [progress, setProgress] = useState('onTask');
    const [progressNav1, setProgressNav1] = useState('')
    const [progressNav2, setProgressNav2] = useState('')

    const [taskFuncBtn, setTaskFuncBtn] = useState('')

    const [totalCountToAdd, setTotalCountToAdd] = useState('')



    
    const { currentUser } = useContext(AuthContext);
    const database2 = firebase.firestore();

    // const [totalHours, setTotalHours] = useState([]);
    // if (currentUser != null) {
    //     console.log(currentUser.uid)
    //     database2.collection('users').get().then((users) => {
    //         users.forEach(doc => {
    //             if (currentUser.uid === doc.data().uid) {
    //                 // setUserName(doc.data().name)
    //                 // setTotalHoursFocus(doc.data().historyOfFucus.reduce((partialSum, a) => partialSum + a, 0))
    //                 // setTotalHours[...doc.data().totalHours]
    //                 console.log(doc.data())
    //             }
    //         })
    //     })
    // };

    // useEffect(() => {
    //     setTimeout(() => {
    //         database2.collection('users').doc(currentUser.docId).update({
    //             // historyOfFucus : currentUser.historyOfFucus.concat(10)
    //         })

    //     },2000)

    // },[])







    useEffect(() => {
        if (timerSeconds == 1) {
            setTimeout(() => { setTimerSeconds(0) }, 1000)
        }

        setTimeout(() => {
            if (roundFoc != 0) {
                let timeCounter = timerForFocus * 60;
                let interval = setInterval(() => {

                    setTimerMinutes(Math.floor(timeCounter / 60));
                    setTimerSeconds(Math.floor(timeCounter % 60));
                    setTimerProgress((timerForFocus * 60 - timeCounter + 1) / (timerForFocus * 60));

                    if (roundFoc == 1) {
                        setTimerProgress((timerForFocus * 60 - timeCounter) / (timerForFocus * 60));
                    }
                    timeCounter -= 1;
                    if (timeCounter <= 0) {

                        clearInterval(interval)
                        setRoundFoc(roundFoc - 1)

                        if (progress === 'onTask') {
                            setTimerForFocus(timeRest - (1 / 60));
                            setProgress('rest')
                            // -> Set Mode
                            setTimeout(() => {
                                setTimerSeconds(0)

                                setOnTaskMode('')
                                setRestMode(' progActive rest')
                                setDoneMode('')
                                console.log(roundFoc)
                                if (roundFoc === 2) {
                                    console.log(roundFoc)
                                    setProgressNav2("progressNavActive");
                                    setProgressNav1("");
                                } else {
                                    console.log(roundFoc)
                                    setProgressNav2("");
                                    setProgressNav1("progressNavActiveReverse");
                                }
                                setProgressVariant('rest2')
                            }, 1000)

                        } else if (progress === 'rest') {

                            if (roundFoc === 1) {
                                // -> Set Mode
                                setProgress('done')
                                setTimeout(() => {
                                    setTimerSeconds(0);
                                    setTimerProgress(100)
                                    setOnTaskMode('')
                                    setRestMode('')
                                    setDoneMode(' progActive done')
                                    setProgressNav2("");
                                    setProgressNav1("");
                                    setProgressVariant('done2')
                                    setTaskFuncBtn('')
                                    console.log(totalCountToAdd)
                                    database2.collection('users').doc(currentUser.docId).update({
                                        historyOfFucus : currentUser.historyOfFucus.concat(totalCountToAdd)
                                    })
                                    
                                }, 1000)
                                setTimeout(() => {
                                    setProgressVariant('')
                                    setTimerProgress(0)
                                }, 3000)

                            } else {
                                setTimerForFocus(timeOnTask - (1 / 60));
                                setProgress('onTask')

                                setTimeout(() => {
                                    setRestMode('')
                                    setOnTaskMode(' progActive ontask')
                                    setProgressNav1("progressNavActive");
                                    setProgressNav2("");
                                    setProgressVariant('ontask2')
                                    setTimerSeconds(0);
                                }, 1000)
                            }

                        }
                    }
                }, 1000);
            }
        }, 2000)


    }, [roundFoc])

    const customFunc = () => {
        let round2 = prompt("Set estimate pomodoros") * 2;
        setDoneMode('')
        setTaskFuncBtn(5)
        if (!isNaN(round2)) {
            setTotalCountToAdd(Math.floor((round2*25)))
            setRoundFoc(round2)
            // Set default value
            setProgress('onTask')
            setOnTaskMode(' progActive ontask')
            setTimerMinutes(timeOnTask)
            setProgressNav1("progressNavActive");
            if (progress == 'onTask') {
                setTimerForFocus(timeOnTask - (1 / 60));
            } else if (progress == 'rest') {
                setTimerForFocus(timeOnTask - (1 / 60));
            }
        } else { round2 = prompt("Set estimate pomodoros (You should enter number of times you want)") * 2; customFunc(); }
    }

    const setTaskFunc = (round) => {
        // console.log(round)
        setTaskFuncBtn(round)
        setRoundFoc(round * 2)
        // Set default value
        setProgress('onTask')
        setDoneMode('')
        setOnTaskMode(' progActive ontask')
        setTimerMinutes(timeOnTask)
        setProgressNav1("progressNavActive");
        setTotalCountToAdd(Math.floor((round*25)))
        if (progress == 'onTask') {
            setTimerForFocus(timeOnTask - (1 / 60));
        } else if (progress == 'rest') {
            setTimerForFocus(timeOnTask - (1 / 60));
        }
    }



    if (currentUser != null) {
        return (
            <>
                <FadeIn>
                    <section className="focusSec">
                        <ProgressBar className={"ProgressBar " + progressVariant} value={timerProgress * 100} size="x-small" />
                        <div className="focusMain container">


                            <FadeIn className="focusSub">
                                {/* PART OF CONTROL MODE */}
                                <div className="controlMode">
                                    <div className={"controlModeIt " + pomo} onClick={() => { setActiveMode("pomoMode") }} >
                                        <span>POMODORO 🍊</span>
                                    </div>
                                    <div className={"controlModeIt" + cust + " disableBtn"} onClick={() => { }}>
                                        <span>CUSTOM TIME ⏱</span>
                                    </div>
                                </div>

                                {/* PART OF PROGRESS MODE */}
                                <div className="progMain">
                                    <span className={'progItem' + onTaskMode}>on task ({Math.floor(roundFoc / 2)})</span>
                                    <i className={"fa-solid " + ((progressNav1 == "progressNavActiveReverse") ? " fa-angles-left " : " fa-angles-right ") + progressNav1}></i>
                                    <span className={'progItem' + restMode}>rest</span>
                                    <i className={"fa-solid fa-angles-right " + progressNav2}></i>
                                    <span className={'progItem' + doneMode}>done</span>
                                </div>

                                {/* {(currentMode != "Custom") ? <Pomodoro /> : <CustomTime />} */}

                                {/* PART OF SHOW TIMING */}
                                <div className="timer">
                                    {/* <span className="timerIt">{(timerMinutes === 0 || timerMinutes < 10) ? "0" + timerMinutes : timerMinutes}</span> */}
                                    <span className="timerIt">{timerMinutes}</span>
                                    <span className="timerIt">:</span>
                                    <span className="timerIt">{(timerSeconds === 0 || timerSeconds < 10) ? "0" + timerSeconds : timerSeconds}</span>
                                </div>

                                {/* PART OF CONTROL POMODORO TIMES */}
                                <div className="btnChooseTask">
                                    <div className="controlBtn">
                                        <button className={(taskFuncBtn === 1) ? "button-85" : ""} onClick={() => { setTaskFunc(1) }} disabled={(taskFuncBtn == '') ? false : true}>1 Task</button>
                                    </div>
                                    <div className="controlBtn">
                                        <button className={(taskFuncBtn === 2) ? "button-85" : ""} onClick={() => { setTaskFunc(2) }} disabled={(taskFuncBtn == '') ? false : true}>2 Task</button>
                                    </div>
                                    <div className="controlBtn">
                                        <button className={(taskFuncBtn === 3) ? "button-85" : ""} onClick={() => { setTaskFunc(3) }} disabled={(taskFuncBtn == '') ? false : true}>3 Task</button>
                                    </div>
                                    <div className="controlBtn">
                                        <button className={(taskFuncBtn === 4) ? "button-85" : ""} onClick={() => { setTaskFunc(4) }} disabled={(taskFuncBtn == '') ? false : true}>4 Task</button>
                                    </div>
                                    <div className="controlBtn">
                                        <button className={(taskFuncBtn === 5) ? "button-85" : ""} onClick={() => { customFunc() }} disabled={(taskFuncBtn == '') ? false : true}>custom</button>
                                    </div>
                                </div>

                            </FadeIn>
                        </div>
                    </section>
                </FadeIn>

                {/* <div id="scrollDown" className="scroll-downs" ><div className="mousey"><div className="scroller"></div></div></div> */}

                <FadeIn>
                    <section className="soundSec">
                        <Sound />
                    </section>
                </FadeIn>



            </>
        )
    } else {
        return <Navigate replace to="/signin" />
    }
}