// import css 
import './css/Footer.css'

// import Library
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in';
import firebaseConfig, { AuthContext } from '../services/Auth'
import { Navigate } from 'react-router-dom'

export const Footer = () => {
    const { currentUser } = useContext(AuthContext);

    const logo = require('../logo/ViblyLogo.png')


    if (currentUser != null) {
        return (
            <>
                <footer>
                    <FadeIn className="footer container">
                        <div className="projectName">

                            {/* FIXME: Fix link to redirect */}
                            <Link to="/">
                                <img className="projectNameLogo" src={logo} alt="logo" />
                                <span>VIBLY</span>
                            </Link>
                            <span className="desVibly">
                                encourage to manage time for better focus.
                            </span>
                        </div>
                        <div className="hr"/>
                        <div className="footerNav">
                            <ul>
                                <li>Navigation</li>
                                <li><a href="/">Home</a></li>
                                <li><a href="/focus">Focus</a></li>
                                <li><a href="/community">Community</a></li>
                                <li><a href="/badges">Badges</a></li>
                            </ul>
                            <ul>
                                <li>Reference</li>
                                <li><a href="https://en.wikipedia.org/wiki/Pomodoro_Technique">Pomodoro</a></li>
                                <li><a href="https://www.healthline.com/health/mental-health/how-to-stay-focused#drink-coffee">Focusing</a></li>
                                <li><a href="https://corporatefinanceinstitute.com/resources/careers/soft-skills/time-management-list-tips/">Time Management</a></li>
                            </ul>
                            <ul>
                                <li>More</li>
                                <li><a href="/signup">Sign up</a></li>
                                <li><a href="/signin">Sign in</a></li>
                                <li><a href="/signin" onClick={() => firebaseConfig.auth().signOut()}>Sign out</a></li>
                            </ul>
                        </div>
                        <div className="hr"/>
                        <div className="copyright">
                            <div className="developBy2">
                                <span><i className="fa-solid fa-code"></i>&nbsp;Develop by <b><i className="developByAnimateCharcter">MyKiWx x DubuShii x CarePhat</i></b></span>
                            </div>
                        </div>
                    </FadeIn>

                </footer>
            </>
        )
    } else {
        return ""
    }










}