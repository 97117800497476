// -> Import css
import "./css/Sound.css";

// -> Import Libraries
import { React, useState, useRef, useContext, useEffect } from "react";
import FadeIn from "react-fade-in";

export const Sound = () => {
    const audio1 = new Audio(require('../sounds/cafe/Crowdchatter.mp3'))
    const audio2 = new Audio(require('../sounds/cafe/Pencilwriting.mp3'))
    const audio3 = new Audio(require('../sounds/cafe/Windchimes.mp3'))
    const audio4 = new Audio(require('../sounds/camping/Firecrackling.mp3'))
    const audio5 = new Audio(require('../sounds/farm/Farmambience.mp3'))
    const audio6 = new Audio(require('../sounds/forest/Bearroar.mp3'))
    const audio7 = new Audio(require('../sounds/forest/Birds.mp3'))
    const audio8 = new Audio(require('../sounds/forest/Brook.mp3'))
    const audio9 = new Audio(require('../sounds/forest/Forest.mp3'))
    const audio10 = new Audio(require('../sounds/music/Breeze.mp3'))
    const audio11 = new Audio(require('../sounds/music/Watmidoin.mp3'))
    const audio12 = new Audio(require('../sounds/ocean/Beachwaves.mp3'))
    const audio13 = new Audio(require('../sounds/ocean/Lightwind.mp3'))
    const audio14 = new Audio(require('../sounds/ocean/Underwater.mp3'))
    const audio15 = new Audio(require('../sounds/on the way/Airplaneflying.mp3'))
    const audio16 = new Audio(require('../sounds/on the way/Trainhorn.mp3'))
    const audio17 = new Audio(require('../sounds/public space/Cityambience.mp3'))
    const audio18 = new Audio(require('../sounds/public space/Citytraffic.mp3'))
    const audio19 = new Audio(require('../sounds/rainning/Nightraining.mp3'))
    const audio20 = new Audio(require('../sounds/rainning/Rumblingthunder.mp3'))
    const audio21 = new Audio(require('../sounds/rainning/Thunderrain.mp3'))


    const imageS1 = require("../ImageForSound/cafe/Crowdchatter.png")
    const imageS2 = require("../ImageForSound/cafe/Pencilwriting.png")
    const imageS3 = require("../ImageForSound/cafe/Windchimes.png")
    const imageS4 = require("../ImageForSound/camping/Firecrackling.png")
    const imageS5 = require("../ImageForSound/farm/Farmambience.png")
    const imageS6 = require("../ImageForSound/forest/Bearroar.png")
    const imageS7 = require("../ImageForSound/forest/Birds.png")
    const imageS8 = require("../ImageForSound/forest/Brook.png")
    const imageS9 = require("../ImageForSound/forest/Forest.png")
    const imageS10 = require("../ImageForSound/music/Breeze.png")
    const imageS11 = require("../ImageForSound/music/Watmidoin.png")
    const imageS12 = require("../ImageForSound/ocean/Beachwaves.png")
    const imageS13 = require("../ImageForSound/ocean/Lightwind.png")
    const imageS14 = require("../ImageForSound/ocean/Underwater.png")
    const imageS15 = require("../ImageForSound/on the way/Airplaneflying.png")
    const imageS16 = require("../ImageForSound/on the way/Trainhorn.png")
    const imageS17 = require("../ImageForSound/public space/Cityambience.png")
    const imageS18 = require("../ImageForSound/public space/Citytraffic.png")
    const imageS19 = require("../ImageForSound/raining/Nightraining.png")
    const imageS20 = require("../ImageForSound/raining/Rumblingthunder.png")
    const imageS21 = require("../ImageForSound/raining/Thunderrain.png")

    audio1.loop = true;
    audio2.loop = true;
    audio3.loop = true;
    audio4.loop = true;
    audio5.loop = true;
    audio6.loop = true;
    audio7.loop = true;
    audio8.loop = true;
    audio9.loop = true;
    audio10.loop = true;
    audio11.loop = true;
    audio12.loop = true;
    audio13.loop = true;
    audio14.loop = true;
    audio15.loop = true;
    audio16.loop = true;
    audio17.loop = true;
    audio18.loop = true;
    audio19.loop = true;
    audio20.loop = true;
    audio21.loop = true;


    audio1.volume = 0.1
    audio2.volume = 0.2
    audio3.volume = 0.2
    audio4.volume = 0.1
    audio5.volume = 0.3
    audio6.volume = 0.3
    audio7.volume = 0.5
    audio8.volume = 0.4
    audio9.volume = 0.5
    audio10.volume = 0.1
    audio11.volume = 0.1
    audio12.volume = 0.3
    audio13.volume = 0.5
    audio14.volume = 0.3
    audio15.volume = 0.5
    audio16.volume = 0.5
    audio17.volume = 0.5
    audio18.volume = 0.3
    audio19.volume = 0.5
    audio20.volume = 0.5
    audio21.volume = 0.5
    




    return (
        <>
            <div className="soundMain container">
                <div className="headerSound">
                    <span>Sounds 🎧</span>
                    <span>&nbsp;&nbsp;&nbsp;( choose sounds for relaxing. )</span>
                    <div className="hrHeader"></div>
                </div>
                <div className="soundPathMain">
                    {/* <ul className="soundPath"> */}
                    <FadeIn className="soundPath">
                

                <li id="s1" onClick={() => { (audio1.paused) ? audio1.play() :  audio1.pause(); document.getElementById("s1").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS1})`}} />
                </li> 
                <li id="s2" onClick={() => { (audio2.paused) ? audio2.play() :  audio2.pause(); document.getElementById("s2").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS2})`}} />
                </li> 
                <li id="s3" onClick={() => { (audio3.paused) ? audio3.play() :  audio3.pause(); document.getElementById("s3").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS3})`}} />
                </li> 
                <li id="s4" onClick={() => { (audio4.paused) ? audio4.play() :  audio4.pause(); document.getElementById("s4").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS4})`}} />
                </li> 
                <li id="s5" onClick={() => { (audio5.paused) ? audio5.play() :  audio5.pause(); document.getElementById("s5").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS5})`}} />
                </li> 
                <li id="s6" onClick={() => { (audio6.paused) ? audio6.play() :  audio6.pause(); document.getElementById("s6").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS6})`}} />
                </li> 
                <li id="s7" onClick={() => { (audio7.paused) ? audio7.play() :  audio7.pause(); document.getElementById("s7").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS7})`}} />
                </li> 
                <li id="s8" onClick={() => { (audio8.paused) ? audio8.play() :  audio8.pause(); document.getElementById("s8").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS8})`}} />
                </li> 
                <li id="s9" onClick={() => { (audio9.paused) ? audio9.play() :  audio9.pause(); document.getElementById("s9").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS9})`}} />
                </li> 
                <li id="s10" onClick={() => { (audio10.paused) ? audio10.play() :  audio10.pause(); document.getElementById("s10").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS10})`}} />
                </li> 
                <li id="s11" onClick={() => { (audio11.paused) ? audio11.play() :  audio11.pause(); document.getElementById("s11").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS11})`}} />
                </li> 
                <li id="s12" onClick={() => { (audio12.paused) ? audio12.play() :  audio12.pause(); document.getElementById("s12").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS12})`}} />
                </li> 
                <li id="s13" onClick={() => { (audio13.paused) ? audio13.play() :  audio13.pause(); document.getElementById("s13").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS13})`}} />
                </li> 
                <li id="s14" onClick={() => { (audio14.paused) ? audio14.play() :  audio14.pause(); document.getElementById("s14").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS14})`}} />
                </li> 
                <li id="s15" onClick={() => { (audio15.paused) ? audio15.play() :  audio15.pause(); document.getElementById("s15").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS15})`}} />
                </li> 
                <li id="s16" onClick={() => { (audio16.paused) ? audio16.play() :  audio16.pause(); document.getElementById("s16").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS16})`}} />
                </li> 
                <li id="s17" onClick={() => { (audio17.paused) ? audio17.play() :  audio17.pause(); document.getElementById("s17").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS17})`}} />
                </li> 
                <li id="s18" onClick={() => { (audio18.paused) ? audio18.play() :  audio18.pause(); document.getElementById("s18").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS18})`}} />
                </li> 
                <li id="s19" onClick={() => { (audio19.paused) ? audio19.play() :  audio19.pause(); document.getElementById("s19").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS19})`}} />
                </li> 
                <li id="s20" onClick={() => { (audio20.paused) ? audio20.play() :  audio20.pause(); document.getElementById("s20").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS20})`}} />
                </li> 
                <li id="s21" onClick={() => { (audio21.paused) ? audio21.play() :  audio21.pause(); document.getElementById("s21").classList.toggle("activeSound") }} > 
                    <div className="bgSound" style={{backgroundImage: `url(${imageS21})`}} />
                </li> 





            </FadeIn>
                    {/* </ul> */}
                </div>
            </div>
        </>
    );
};


    // const [allSound, setAllSound] = useState({
    //     'name': "firesound",
    //     'path': "...",
    //     'onplay': true
    // })


    // const playSound = () => {
        // audio.play()
        // audio.volume = 0.1
        // audio.loop = true
    // }

    //   const music = () => {
    //     const audio = new Audio(ringer);
    //     audio.loop = true;
    //     return (
    //       <>
    //         <button
    //           onClick={() => {
    //             audio.loop = true;
    //             audio.play();
    //           }}
    //         >
    //           Play
    //         </button>
    //         <button onClick={() => (audio.loop = false)}>Pause</button>
    //       </>
    //     );
    //   };