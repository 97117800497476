// -> Import css
import './App.css';

// -> Import Libraries
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import FadeIn from 'react-fade-in';

// -> Import Components
import { SignUp } from './components/SignUp'
import { Navbar } from './components/Navbar'
import { SignIn } from './components/SignIn'
import { Home } from './components/Home'
import { Footer } from './components/Footer'
import { Focus } from './components/Focus'
import { Community } from './components/Community'
import { Badges } from './components/Badges'

// -> Import Provider
import { AuthProvider } from './services/Auth'

function App() {
    
    return (
        <AuthProvider>
            <div className="App">
                <BrowserRouter>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/signin" element={<SignIn/>}/>
                        <Route path="/focus" element={<Focus/>}/>
                        <Route path="/community" element={<Community/>}/>
                        <Route path="/badges" element={<Badges/>}/>
                        <Route path="*" exact={true} element={<Home/>}/>
                    </Routes>
                    <Footer />
                    
                </BrowserRouter>
            </div>
        </AuthProvider>

    );
}

export default App;
