// import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useState, useEffect } from 'react'
import { getStorage, ref, getMetadata } from "firebase/storage";


//-> Config firebase
const firebaseConfig = firebase.initializeApp({
    apiKey: "AIzaSyCPg6q0la4Q5AggZpEAIHhyFasqVSPRHTs",
    authDomain: "vibly2-f4029.firebaseapp.com",
    projectId: "vibly2-f4029",
    storageBucket: "vibly2-f4029.appspot.com",
    messagingSenderId: "262613719697",
    appId: "1:262613719697:web:2ad405fa328af5c6427d30",
    measurementId: "G-KR509D6868"
});

export const AuthContext = React.createContext();



export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [runTimes, setRunTimes] = useState(true);
    
    
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    } else {
        firebase.app(); // if already initialized, use that one
    }
    
    const database2 = firebase.firestore()

    useEffect(() => {
        if (currentUser != null && runTimes) {
            database2.collection('users').get().then((users) => {
                users.forEach(doc => {
    
                    if (currentUser.uid === doc.data().uid) {
                        setCurrentUser({
                            email: doc.data().email,
                            name: doc.data().name,
                            faculty: doc.data().faculty,
                            instagram: doc.data().instagram,
                            badges: {
                                level1: doc.data().badges[0],
                                level2: doc.data().badges[1],
                                level3: doc.data().badges[2],
                                level4: doc.data().badges[3],
                                level5: doc.data().badges[4],
                                level6: doc.data().badges[5]
                            },
                            historyOfFucus: doc.data().historyOfFucus,
                            uid: doc.data().uid,
                            docId: doc.id
                        })
                        setRunTimes(false)
                    }
                })
            })
        } else if (currentUser == undefined) {
            database2.collection('users').get().then( (users) => {
                users.forEach( doc => {
                    try {
                        if (currentUser.uid === doc.data().uid) {
                            setCurrentUser({
                                email: doc.data().email,
                                name: doc.data().name,
                                faculty: doc.data().faculty,
                                instagram: doc.data().instagram,
                                badges: {
                                    level1: doc.data().badges[0],
                                    level2: doc.data().badges[1],
                                    level3: doc.data().badges[2],
                                    level4: doc.data().badges[3],
                                    level5: doc.data().badges[4],
                                    level6: doc.data().badges[5]
                                },
                                historyOfFucus: doc.data().historyOfFucus,
                                uid: doc.data().uid
        
                            })
                            setRunTimes(false)
                        }
                    } catch (error) {
                        // console.log(error)
                        
                    }
                })
            })

        }
    
    }, [currentUser])
    


    useEffect(() => {
        setCurrentUser(null)
        firebaseConfig.auth().onAuthStateChanged((user) => {
            setCurrentUser(user)
            setLoading(false)
            // setTimeout(() => {setLoading(false)}, 1000)
        })
    }, [])

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <AuthContext.Provider value={{currentUser, setCurrentUser}}>
                {children}
        </AuthContext.Provider>
    )
}

export default firebaseConfig ;