import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import firebaseConfig, { AuthContext } from "../services/Auth";
import './css/SignIn.css'
import FadeIn from 'react-fade-in';



export const SignIn = () => {
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [ createAccountText, setCreateAccountText ] = useState(false);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        setCreateAccountText(true);
        const { email, password } = e.target.elements;
        try {
            // setTimeout(() => {setPasswordShown(true)}, 1000)  
            setTimeout(() => {
                firebaseConfig
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
            }, 1050)
        } catch (error) {
            alert(error);
        }
    };

    const { currentUser } = useContext(AuthContext);
    if (currentUser) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <div className="filterSignUp"></div>
            <div className="backgroundSignUp" style={{backgroundImage: `url(${require("../image/Background/bg2.jpeg")})`}}></div>
            {/* <div class="wrapper"></div> */}
            <div className="container containerSignUp containerSignIn">
                <FadeIn className="brandSignUp brandSignIn">
                        <span className="brandNamesignUp textRainbow">VIBLY</span>
                        <span className="brandDesSignUp">Sign in or create an account</span>
                </FadeIn>
                <FadeIn className="formMain">

                    <h1 className="titleSignUp">Sign In</h1>
                    <span>New member?&nbsp;<a className="linkSignUp" href="/signup">Create an account</a></span>
                    <form onSubmit={handleSubmit}  className="formContainerSignUp">
                        <div className="txtFieldSignUp emailColorSignUp">
                            <input type="email" name="email" className="formControlSignUP emailColorSignUp" aria-describedby="emailHelp" autoComplete="off" required/>
                            <span className="emailColorSignUp"></span>
                            <label htmlFor="exampleInputEmail1" className="form-label icon">Email address</label>
                        </div>
                        
                        <div className="txtFieldSignUp passwordColorSignUp passwordColorSignIn">
                            <input type={!passwordShown ? "password" : "text"} name="password" className="formControlSignUP passwordColorSignUp"  autoComplete='off' required/>
                            <span className="passwordColorSignUp"></span>
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <div className="showPassword" onClick={()=>{setPasswordShown(!passwordShown)}}>
                                {!passwordShown ? <i className="fa-solid fa-eye-slash" /> : <i class="fa-solid fa-eye" />}
                            </div>
                        </div>
                       
                        <div className="submitBtn">
                            <button type="submit" className="btnSubmitSignUp" ><i className={(createAccountText) ? "fa fa-circle-o-notch fa-spin" : ""} /> Sign in</button>
                        </div>
                    </form>

                </FadeIn>
                <div className="developBy">
                    <span><i className="fa-solid fa-code"></i>&nbsp;Develop by <b><i className="developByAnimateCharcter">MyKiWx x DubuShii x CarePhat</i></b></span>
                </div>
                
            </div>
            
        </>
    )


};
